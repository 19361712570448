jQuery(window).on('load',function () {

	/* Loading */
	jQuery('html').addClass('loaded');

	setTimeout(function () {

		jQuery('.loading').hide();

	}, 1500);

	/* Isotope */

	var grid = jQuery('.article-container');

	grid.imagesLoaded(function () {
		grid.isotope({
			itemSelector: '.item',
			percentPosition: true,
			masonry: {
				columnWidth: '.grid-sizer'
			}
		});
		jQuery(document).bind("yith_sort", function (e, elem) {
			elem.imagesLoaded(function () {
				grid.append(elem).isotope('appended', elem);
			});
		});
	});

	/* Truncate */

	function truncateHeight() {

		var truncate = jQuery('.js-truncate');

		if (truncate.closest('.js-truncate-wrapper').length > 0) {

			truncate.each(function () {

				jQuery(this).height(0);

				var wrapper = jQuery(this).closest('.js-truncate-wrapper');
				var wrapperHeight = wrapper.outerHeight() - 48;

				// console.log(wrapperHeight);

				var titleHeight = wrapper.find('.title').outerHeight();
				var dateHeight = wrapper.find('.date').outerHeight();
				var readmoreHeight = wrapper.find('.read-more').outerHeight() + 64;

				var availableSpace = wrapperHeight - (titleHeight + dateHeight + readmoreHeight);

				// console.log(availableSpace);

				jQuery(this).height(availableSpace);

				/*if ( jQuery(this).closest('.js-truncate-wrapper').hasClass('js-small-block') ) {

				 console.log('Wrapper height ', wrapperHeight);
				 console.log('Title height ', titleHeight);
				 console.log('Date height ', dateHeight);
				 console.log('Read more height ', readmoreHeight);
				 console.log('Available space ', availableSpace);

				 }*/

			});

		}

	}

	if (jQuery('.article-container').length > 0) {

		grid.on('layoutComplete',
			function (event, laidOutItems) {
				/*console.log( 'Isotope layout completed on ' +
				 laidOutItems.length + ' items' );*/

				truncateHeight();

				jQuery('.js-truncate').dotdotdot({
					watch: 'window'
				});

				jQuery('.title').dotdotdot({
					watch: 'window'
				});

			}
		);

		setTimeout(function () {

			grid.isotope('layout');

		}, 250);

	} else {

		truncateHeight();

		jQuery('.js-truncate').dotdotdot({
			watch: 'window'
		});

		jQuery('.title').dotdotdot({
			watch: 'window'
		});

	}


	jQuery(window).on('resize', function () {

		//console.log('resize!');

		truncateHeight();

	});

});
