jQuery(document).ready(function ($) {
// Where to buy
	$("#vets_search").validate({
		errorClass: "is-error",
		rules: {
			postcode: {
				required: true
			}
		},
		errorElement: 'div'
	})
})