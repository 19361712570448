/* Validation */

jQuery.validator.addMethod("postcodeUK", function (value, element) {
	return this.optional(element) || /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i.test(value);
}, "Please enter a valid postcode");

jQuery.validator.addMethod('phoneUK', function (phone_number, element) {
	return this.optional(element) || phone_number.length > 9 &&
		phone_number.match(/^(\(?(0|\+44)[1-9]{1}\d{1,4}?\)?\s?\d{3,4}\s?\d{3,4})$/);
}, 'Please specify a valid phone number');

jQuery.validator.addMethod("lettersonly", function (value, element) {
	return this.optional(element) || /^[a-z]+$/i.test(value);
}, "Letters only please");

$.validator.addMethod("extension", function (value, element, param) {
	param = typeof param === "string" ? param.replace(/,/g, "|") : "png|jpe?g|gif";
	return this.optional(element) || value.match(new RegExp("\\.(" + param + ")$", "i"));
}, $.validator.format("Please upload a file with a valid extension."));

jQuery.validator.addMethod("trioDate", function (value, element) {
	return this.optional(element) || value.match(/^\d{1,2}\/\d{4}$/);
}, "Please enter a date in the format mm/yyyy");

function is_valid_url(url) {
	return /^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(url);
}

jQuery.validator.addMethod("nohttpurl", function (value, element) {
	// now check if valid url
	// return this.optional(element) || value.match(/^(www\.)[A-Za-z0-9_-]+\.+[A-Za-z0-9.\/%&=\?_:;-]+$/);
	return this.optional(element) || value.match(/^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
}, "Please enter a valid URL.");