// Homepage product search
jQuery(document).ready(function ($) {
	var hasSelection;

	$('#product-search').on('submit', function (e) {
		var hasSelection = false;
		e.preventDefault();

		$(this).find('.selectpicker').each(function () {

			if ($('#product-search .selectpicker option[value!=""]:selected').length > 0) {

				hasSelection = true;

			}

		});

		console.log(hasSelection);

		if (hasSelection === true) {

			$(this)[0].submit();

		} else {

			$("#product-search").validate({
				errorClass: "is-error",
				rules: {
					animals_filter: {
						required: true
					},
					area: {
						required: true
					}
				},
				messages: {
					animals_filter: {
						required: " "
					},
					area: {
						required: " "
					}
				},
				errorElement: 'div'
			});
			$("#product-search").valid();

		}

	});
})