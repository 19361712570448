import $ from 'jquery'
window.$ = window.jQuery = $
import 'slick-carousel'

var jQueryBridget = require('jquery-bridget')
var vex = require('vex-js')
require('dotdotdot')
require('jquery-validation')
require('jquery-doubletaptogo')
require('jquery-match-height')

require('jquery.elastic')
require('./vendors/fancy-select/fancySelect')
require('imagesloaded')
require('rateyo')

var Isotope = require('isotope-layout')
jQueryBridget('isotope',Isotope,$)

require('./vendors/widow-fix/jquery.widowFix-1.3.2')

require('./validators')
require('./vets_search')
require('./homepage_search')
require('./forms')

jQuery(document).ready(function ($) {
	var previousTime = 0

	// Search

	$('form[name="search_form"]').validate({
		errorClass: "is-error",
		rules: {
			s: {
				required: true,
				minlength: 3
			}
		},
		messages: {
			s: {
				required: '',
				minlength: ''
			}
		},
		errorElement: 'div',
		errorPlacement: function (error, element) {
			var parent = element.closest('form');
			if (parent.length) {
				error.appendTo(parent);
			} else {
				error.insertAfter(element);
			}
		}
	});

	$('form[name="search_form_mobile"]').validate({
		errorClass: "is-error",
		rules: {
			s: {
				required: true,
				minlength: 3
			}
		},
		messages: {
			s: {
				required: '',
				minlength: ''
			}
		},
		errorElement: 'div',
		errorPlacement: function (error, element) {
			var parent = element.closest('form');
			if (parent.length) {
				error.appendTo(parent);
			} else {
				error.insertAfter(element);
			}
		}
	});


	// Login

	$('#loginform').validate({
		errorClass: "is-error",
		rules: {
			log: {
				required: true
			},
			pwd: {
				required: true
			}
		},
		/*messages: {
			s: {
				required: '',
				minlength: ''
			}
		},*/
		errorElement: 'span'/*,
        errorPlacement: function (error, element) {
            var parent = element.closest('div');
            if (parent.length) {
                error.appendTo(parent);
            } else {
                error.insertAfter(element);
            }
        }*/
	});


	// Forgotten password

	$('#wp_pass_reset').validate({
		errorClass: "is-error",
		rules: {
			user_input: {
				required: true,
				email: true
			}
		},
		/*messages: {
			s: {
				required: '',
				minlength: ''
			}
		},*/
		errorElement: 'div'/*,
        errorPlacement: function (error, element) {
            var parent = element.closest('div');
            if (parent.length) {
                error.appendTo(parent);
            } else {
                error.insertAfter(element);
            }
        }*/
	});


	// Postcode searches

	$('form[name="find_a_vet_wide"]').validate({
		errorClass: "is-error",
		rules: {
			postcode: {
				required: true,
				postcodeUK: true
			}
		},
		messages: {
			postcode: {
				required: '',
				postcodeUK: ''
			}
		},
		errorElement: 'div',
		errorPlacement: function (error, element) {
			var parent = element.closest('form');
			if (parent.length) {
				error.appendTo(parent);
			} else {
				error.insertAfter(element);
			}
		}
	});

	$('form[name="find_a_vet_small"]').validate({
		errorClass: "is-error",
		rules: {
			postcode: {
				required: true,
				postcodeUK: true
			}
		},
		messages: {
			postcode: {
				required: '',
				postcodeUK: ''
			}
		},
		errorElement: 'div',
		errorPlacement: function (error, element) {
			var parent = element.closest('form');
			if (parent.length) {
				error.appendTo(parent);
			} else {
				error.insertAfter(element);
			}
		}
	});

	$('form[name="find_a_vet_sidebar"]').validate({
		errorClass: "is-error",
		rules: {
			postcode: {
				required: true,
				postcodeUK: true
			}
		},
		messages: {
			postcode: {
				required: '',
				postcodeUK: ''
			}
		},
		errorElement: 'div',
		errorPlacement: function (error, element) {
			var parent = element.closest('form');
			if (parent.length) {
				error.appendTo(parent);
			} else {
				error.insertAfter(element);
			}
		}
	});


	// Registration

	$('form[name="pet_register"]').validate({
		//ignore: ':not(:visible)',
		errorClass: "is-error",
		rules: {
			first_name: {
				required: true,
				lettersonly: true
			},
			last_name: {
				required: true,
				lettersonly: true
			},
			email_address: {
				required: true,
				email: true
			},
			telephone: {
				required: true,
				phoneUK: true
			},
			postcode: {
				required: true,
				postcodeUK: true
			},
			'animal_dob[0]': {
				trioDate: true
			},
			'animal_dob[1]': {
				trioDate: true
			},
			'animal_dob[2]': {
				trioDate: true
			},
			'animal_dob[3]': {
				trioDate: true
			},
			'animal_dob[4]': {
				trioDate: true
			},
			terms: "required"
		},
		messages: {
			terms: {
				required: "Please read and agree to the terms and conditions."
			}
		},
		errorElement: 'span',
		errorPlacement: function (error, element) {
			var parent = element.closest('div');
			if (parent.length) {
				error.appendTo(parent);
			} else {
				error.insertAfter(element);
			}
		}
	});

	$('form[name="vet_register_2"]').validate({
		//ignore: ':not(:visible)',
		errorClass: "is-error",
		rules: {
			first_name: {
				required: true,
				lettersonly: true
			},
			last_name: {
				required: true,
				lettersonly: true
			},
			email_address: {
				required: true,
				email: true
			},
			telephone: {
				required: true,
				phoneUK: true
			},
			practice_name: {
				required: true
			},
			wholesale_no: {
				required: true
			},
			address_1: {
				required: true
			},
			towncity: {
				required: true
			},
			county: {
				required: true
			},
			postcode: {
				required: true,
				postcodeUK: true
			},
			password: {
				required: true,
				minlength: 8
			},
			confirm_password: {
				equalTo: "#password"
			},
			facebook_url: {
				nohttpurl: true
			},
			twitter_url: {
				nohttpurl: true
			},
			website_address: {
				nohttpurl: true
			},
			terms: "required"
		},
		messages: {
			terms: {
				required: "Please read and agree to the terms and conditions."
			}
		},
		errorElement: 'span',
		errorPlacement: function (error, element) {
			var parent = element.closest('div');
			if (parent.length) {
				error.appendTo(parent);
			} else {
				error.insertAfter(element);
			}
		}
	});


	/* Animal Types */

	function animal_types_event() {
		$('.animal_types_select').on('change', function () {
			var id = $(this).find(':selected').data('id');

			$.ajax({
				method: "POST",
				url: "/ajax/breeds/",
				data: {
					type_of_animal: this.value
				}
			})
				.done(function (msg) {
					var mySelect = $('.animal_breeds_select_' + id);

					mySelect.fancySelect();
					mySelect.html('');
					mySelect.append(msg);
					mySelect.trigger('update.fs');
				});
		});
	}

	animal_types_event();

	/* Smooth scroll */

	$('a[href*="#"] :not([href="#"])').click(function () {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			if (target.length) {

				if ($('.sticky-element').length > 0) {

					var stickyHeight = $('.sticky-element').outerHeight();

					$('html,body').animate({
						scrollTop: target.offset().top - stickyHeight
					}, 750);
					return false;

				} else {

					$('html,body').animate({
						scrollTop: target.offset().top
					}, 750);
					return false;

				}
			}
		}
	});

	$('.js-backtotop').click(function (e) {
		e.preventDefault();

		$('html,body').animate({
			scrollTop: 0
		}, 750);
		return false;

	});

	/* Sticky */

	if ($('.sticky-element').length > 0) {

		var stickyWrapper = $('.sticky-wrapper');
		var stickyElement = $('.sticky-element');
		var stickyHeight = stickyElement.outerHeight();

		stickyWrapper.each(function () {
			$(this).height(stickyHeight);
		});

		// grab the initial top offset of the navigation
		var stickyOffsetTop = stickyElement.offset().top + 10;

		// our function that decides weather the navigation bar should have "fixed" css position or not.
		var stickyFunc = function () {
			var scrollTop = $(window).scrollTop(); // our current vertical position from the top

			//console.log(scrollTop + ' ' + stickyOffsetTop);
			// if we've scrolled more than the navigation, change its position to fixed to stick to top,
			// otherwise change it back to relative
			if (scrollTop > stickyOffsetTop) {
				$('.sticky-element').css({'position': 'fixed', 'top': 0, 'left': 0, 'z-index': 999});
			} else {
				$('.sticky-element').css({'position': 'relative', 'z-index': 0});
			}
		};

		// run our function on load
		stickyFunc();

		// and run it again every time you scroll
		$(window).scroll(function () {
			stickyFunc();
		});

	}


	/* Tooltip */

	$('[data-toggle="tooltip"]').on('click', function (e) {
		e.preventDefault();
	});

	/* Match Height */

	$('.js-match-height-row').matchHeight();

	$('.js-match-height').matchHeight({
		byRow: false,
		property: 'max-height'
	});

	function matchParent() {

		var vwResize = $(window).width();
		var vhResize = $(window).height();

		var matchParent = $('.js-match-parent').closest('.js-match-height').outerHeight();

		$('.js-match-parent').height(matchParent);

		if (vwResize > 640) {
			$('.js-match-parent').height(matchParent);
		} else {
			$('.js-match-parent').css('height', '200px');
		}

	}

	$.fn.matchHeight._afterUpdate = function (event, groups) {

		matchParent();

	}

	/* Widow fix */

	$('.js-widow').widowFix({
		linkFix: true
	});

	/* Sliders */

	$('.main-banner').slick({
		arrows: false,
		dots: true,
		draggable: false,
		infinite: false,
		autoplay: true,
		autoplaySpeed: 8000,
		pauseOnHover: false
	});

	/* Fancy Select */

	var $selectPicker = $('.selectpicker, .gfield_select, .gfield_time_ampm select');
	$selectPicker.fancySelect();

	$selectPicker.fancySelect().on('change.fs', function () {
		$(this).trigger('change.$');
	});

	/* Select url */

	var currentPath = window.location.pathname;

	$('#dashboard_menu').on('change', function () {

		var url = $(this).val();

		if (url) {
			if (url != currentPath) {
				window.location = url;
			}
		}

		return false;

	});


	/* Add pet */

	var petCount = 1;

	$('.js-add-pet').on('click', function (e) {

		e.preventDefault();

		$(".js-pets").append(
			"<div class='relative js-added-pet'>\
				<a href='#' class='absolute top-0 right-0 mt1 red js-remove-pet'>Remove</a>\
				<hr class='mt1 mb3'>\
				<div class='mxn1 clearfix'>\
					<div class='col col-12 sm-col-6 px1'>\
						<label class='block fw500 navy mb1' for='animal_name'>Animal Name</label>\
						<input id='animal_name_" + petCount + "' name='animal_name[" + petCount + "]' type='text' class='block col-12 mb3 field-big' placeholder='Animal Name'>\
						</div>\
						<div class='col col-12 sm-col-6 px1'>\
							<label class='block fw500 navy mb1' for='animal_type'>Type of Animal</label>\
							<select id='animal_type_" + petCount + "' name='animal_type[" + petCount + "]' class='selectpicker animal_types_select' data-class='fancy-select-big mb3'>\
								<option value=''>Select...</option>\
							</select>\
						</div>\
					</div>\
					<div class='mxn1 clearfix'>\
						<div class='col col-12 sm-col-6 px1'>\
							<label class='block fw500 navy mb1' for='animal_breed'>Breed of Animal</label>\
							<select id='animal_breed_" + petCount + "' name='animal_breed[" + petCount + "]' class='selectpicker animal_breeds_select_" + petCount + "' data-class='fancy-select-big mb3'>\
								<option value=''>Select...</option>\
							</select>\
						</div>\
						<div class='col col-12 sm-col-6 px1'>\
							<label class='block fw500 navy mb1' for='animal_dob'>Date of Birth</label>\
							<input id='animal_dob_" + petCount + "' name='animal_dob[" + petCount + "]' type='text' class='block col-12 mb3 field-big' placeholder='MM/YYYY'>\
						</div>\
					</div>\
				</div>"
		);

		var $animal_options = $("#animal_type_0 > option").clone();

		$animal_options.each(function () {
			var option = $(this).attr("data-id", petCount);
			$('#animal_type_' + petCount).append(option);
		});

		var $selectPicker = $('.selectpicker, .gfield_select');
		$selectPicker.fancySelect();
		$selectPicker.fancySelect().on('change.fs', function () {
			$(this).trigger('change.$');
		});

		$('.js-added-pet').slideDown();
		animal_types_event();

		petCount++;
	});

	$('#pet_find_address').on('click', function (e) {
		e.preventDefault();
		var $data = pca_find('XE36-JU77-KY45-MC32', $('#postcode').val(), 'English');
	});

	$('#pet_manual_address').on('click', function (e) {
		e.preventDefault();
		$('.search_address').show();
	});

	$('#select_address').on('change', function () {
		pca_retrieve('XE36-JU77-KY45-MC32', this.value, 'English');
	});


	$('.search_field_address').hide();

	$('.find_postcode').on('click', function (e) {
		e.preventDefault();
		var id = $(this).data('id');
		var value = $('#input_1_' + id).val();

		var hidden_container = $(this).closest('li').next('.gform_hidden');
		var hidden_field = $(hidden_container).children('input');

		var postcode_select = '';
		if ($(hidden_field).attr('id') == 'input_1_34') {
			postcode_select = 'input_1_35';
		} else if ($(hidden_field).attr('id') == 'input_1_39') {
			postcode_select = 'input_1_45';
		} else if ($(hidden_field).attr('id') == 'input_1_46') {
			postcode_select = 'input_1_47';
		}

		if (value.length < 1) {
			$(this).closest('li').addClass('gfield_error');
			$('#input_1_' + id).addClass('is-error');
			$('#input_1_' + id).focus();
		} else {
			//var $data = pca_find_cf('XE36-JU77-KY45-MC32', value, 'English', hidden_field, $('#' + postcode_select));
			var $data = pca_find_live('XE36-JU77-KY45-MC32', value, 'English', hidden_field, $('#' + postcode_select));
		}
	});

	$('.select_location_field select').on('change', function () {
		var id = $(this).attr('id');

		if (id == 'input_1_35') {
			var add1 = $('#input_1_36');
			var add2 = $('#input_1_37');
			var city = $('#input_1_38');
			var county = $('#input_1_40');
		} else if (id == 'input_1_45') {
			var add1 = $('#input_1_41');
			var add2 = $('#input_1_42');
			var city = $('#input_1_43');
			var county = $('#input_1_44');
		} else if (id == 'input_1_47') {
			var add1 = $('#input_1_48');
			var add2 = $('#input_1_49');
			var city = $('#input_1_50');
			var county = $('#input_1_51');
		}

		if (id.length > 0) {
			pca_retrieve_cf('XE36-JU77-KY45-MC32', this.value, 'English', add1, add2, city, county);
		}
	});

	function pca_find_cf(Key, SearchTerm, PreferredLanguage, hidden_field, select_field) {
		$(hidden_field).val('show');
		$(hidden_field).trigger("change");
		var $select = $(select_field);
		var field_container = $(select_field).attr('id').replace('input', 'field');


		$select.html('');
		$select.append($('<option>', {
			value: '',
			text: 'Select Location'
		}));
		data = '{"0": {"Id": "10","StreetAddress": "My Street","Place": "My Place"},"1": {"Id": "20","StreetAddress": "My Street","Place": "My Place"},"2": {"Id": "30","StreetAddress": "My Street","Place": "My Place"}}';

		$.each(JSON.parse(data), function () {
			$.each(this, function () {
				$select.append($('<option>', {
					value: this.Id,
					text: this.StreetAddress + ", " + this.Place
				}));


				$('#' + field_container).slideDown();

				$select.fancySelect();

				$select.trigger('update.fs');

				$select.fancySelect().on('change.fs', function () {
					$(this).trigger('change.$');
				});
			});
		});


	}

	//non-repeatable bug meant pca requests were apparently being used up in a looping fashion
	//this is to only allow 1 every 2 second
	function pca_timer() {
		clickTime = Math.floor(Date.now() / 1000);
		if ((clickTime - previousTime) > 2) {
			previousTime = clickTime;
			//console.log("true");
			return true;
		} else {
			//console.log("false");
			return false;
		}
	}


	//duplicated as wasn't sure how the existing functions worked!
	//so created another new one, again. Please comment code.
	function pca_find_live(Key, SearchTerm, PreferredLanguage, hidden_field, select_field) {
		$(hidden_field).val('show');
		$(hidden_field).trigger("change");
		if (!pca_timer()) return;
		$.getJSON("http://services.postcodeanywhere.co.uk/PostcodeAnywhere/Interactive/Find/v1.10/json3.ws?callback=?",
			{
				Key: Key,
				SearchTerm: SearchTerm,
				PreferredLanguage: PreferredLanguage
			},
			function (data) {
				// Test for an error
				if (data.Items.length == 1 && typeof (data.Items[0].Error) != "undefined") {
					// Show the error message
					alert(data.Items[0].Description);
				} else {
					// Check if there were any items found
					if (data.Items.length == 0)
						alert("Sorry, there were no results");
					else {
						console.log(data.Items);
						//var $select = $('#select_address');
						var $select = $(select_field);
						var field_container = $(select_field).attr('id').replace('input', 'field');
						$select.addClass('selectpicker').data('class', 'fancy-select-big mb3').html('');
						$.each(data, function () {
							$.each(this, function () {
								$select.append($('<option>', {
									value: this.Id,
									text: this.StreetAddress + ", " + this.Place
								}));

								//$('.search_field_address').slideDown();
								$('#' + field_container).slideDown();
								$select.fancySelect();

								$select.trigger('update.fs');

								$select.fancySelect().on('change.fs', function () {
									$(this).trigger('change.$');
								});
							});
						});

					}
				}
			});
	}

	function pca_retrieve_cf(Key, Id, PreferredLanguage, add1, add2, city, county) {
		if (Id.length < 1 || Key.length < 1) {
			return false;
		}
		if (!pca_timer()) return;
		$.getJSON("http://services.postcodeanywhere.co.uk/PostcodeAnywhere/Interactive/RetrieveById/v1.30/json3.ws?callback=?",
			{
				Key: Key,
				Id: Id,
				PreferredLanguage: PreferredLanguage
			},
			function (data) {
				// Test for an error
				if (data.Items.length == 1 && typeof (data.Items[0].Error) != "undefined") {
					// Show the error message
					alert(data.Items[0].Description);
				} else {
					// Check if there were any items found
					if (data.Items.length == 0)
						alert("Sorry, there were no results");
					else {
						var company = data.Items[0].Company;
						if (company.length != 0) {
							$(add1).val(company);
							$(add2).val(data.Items[0].Line1 + ", " + data.Items[0].Line2);
						} else {
							$(add1).val(data.Items[0].Line1);
							$(add2).val(data.Items[0].Line2);
						}
						$(city).val(data.Items[0].PostTown);
						$(county).val(data.Items[0].County);

						$(add1).slideDown();
						$(add2).slideDown();
						$(city).slideDown();
						$(county).slideDown();
					}
				}
			});
	}


	function pca_find(Key, SearchTerm, PreferredLanguage) {
		//console.log("hello world: pca_find");
		if (!pca_timer()) return;
		$.getJSON("http://services.postcodeanywhere.co.uk/PostcodeAnywhere/Interactive/Find/v1.10/json3.ws?callback=?",
			{
				Key: Key,
				SearchTerm: SearchTerm,
				PreferredLanguage: PreferredLanguage
			},
			function (data) {
				// Test for an error
				if (data.Items.length == 1 && typeof (data.Items[0].Error) != "undefined") {
					// Show the error message
					alert(data.Items[0].Description);
				} else {
					// Check if there were any items found
					if (data.Items.length == 0)
						alert("Sorry, there were no results");
					else {
						console.log(data.Items);
						var $select = $('#select_address');
						$select.addClass('selectpicker').data('class', 'fancy-select-big mb3').html('');
						$.each(data, function () {
							$.each(this, function () {
								$select.append($('<option>', {
									value: this.Id,
									text: this.StreetAddress + ", " + this.Place
								}));

								$('.search_field_address').slideDown();

								var $selectPicker = $('.selectpicker, .gfield_select');
								$selectPicker.fancySelect();

								$selectPicker.trigger('update.fs');

								$selectPicker.fancySelect().on('change.fs', function () {
									$(this).trigger('change.$');
								});
							});
						});

					}
				}
			});
	}

	function pca_retrieve(Key, Id, PreferredLanguage) {
		if (!pca_timer()) return;
		$.getJSON("http://services.postcodeanywhere.co.uk/PostcodeAnywhere/Interactive/RetrieveById/v1.30/json3.ws?callback=?",
			{
				Key: Key,
				Id: Id,
				PreferredLanguage: PreferredLanguage
			},
			function (data) {
				// Test for an error
				if (data.Items.length == 1 && typeof (data.Items[0].Error) != "undefined") {
					// Show the error message
					alert(data.Items[0].Description);
				} else {
					// Check if there were any items found
					if (data.Items.length == 0)
						alert("Sorry, there were no results");
					else {
						var company = data.Items[0].Company;
						if (company.length != 0) {
							$('#address_line_1').val(company);
							$('#address_line_2').val(data.Items[0].Line1 + ", " + data.Items[0].Line2);
						} else {
							$('#address_line_1').val(data.Items[0].Line1);
							$('#address_line_2').val(data.Items[0].Line2);
						}
						$('#city').val(data.Items[0].PostTown);
						$('#county').val(data.Items[0].County);

						$('.search_address').slideDown();
					}
				}
			});
	}

	if ($('.search_address').length) {
		$('.search_address').hide();
		var add_1 = $('#address_line_1').val();
		var add_2 = $('#address_line_2').val();
		var add_city = $('#city').val();
		var add_county = $('#county').val();
		if (add_1.length > 0 || add_2.length > 0 || add_city.length > 0 || add_county.length > 0) {
			$('.search_address').slideDown();
		}
	}

	$('body').on('click', '.js-remove-pet', function (e) {

		e.preventDefault();

		//console.log('remove');

		$(this).parent('.js-added-pet').remove();

	});

	/* Reset password */

	$('.js-change-password').click(function (e) {
		e.preventDefault();

		var $vexContent;

		vex.dialog.open({
			className: 'vex-theme-default change-password',
			message: '<h3 class="h2 mt0 mb3">Change your password</h3>',
			input: '<input type="password" name="password" id="password" placeholder="Password..." class="field">\
					<input type="password" name="confirm_password" id="confirm_password" placeholder="Confirm password..." class="field">',
			buttons: [
				$.extend({}, vex.dialog.buttons.YES, {text: 'Submit'}),
				$.extend({}, vex.dialog.buttons.NO, {text: 'Cancel'})
			],
			onSubmit: function (event) {

				event.preventDefault();
				event.stopPropagation();

				$(this).validate({
					errorClass: "is-error",
					rules: {
						password: {
							required: true,
							minlength: 8
						},
						confirm_password: {
							equalTo: "#password"
						}
					},
					errorElement: 'div',
					messages: {
						password: {
							required: 'Please enter a password',
							minlength: 'Your password must be at least 8 characters long'
						},
						confirm_password: {
							equalTo: 'These passwords do not match'
						}
					}
				});

				$vexContent = $(this).parent();

				if ($(this).valid()) {
					$.ajax({
						method: "POST",
						url: "/ajax/resetVet/",
						data: {
							pass: $('#password').val()
						}
					})
						.done(function (msg) {
							if (msg == 'submitted') {
								vex.close($vexContent.data().vex.id);
								vex.dialog.alert('Your password has been changed!');
							} else {
								vex.close($vexContent.data().vex.id);
								vex.dialog.alert('There has been an error changing your password!');
							}
						});

				} else {
					//console.log('invalid');
				}

			}

		});
		return false;
	});

	/* Contact page */

	$('.js-enquiries').hide();

	function enquiryType() {

		var $this = $('#enquiry_type');

		if ($this.val() != null) {

			var enquiry = $this.val();
			//console.log(selected);

			if ($('#' + enquiry).length > 0 && $('#' + enquiry).is(':hidden')) {

				$('.js-enquiries').each(function () {
					if ($(this).is(':visible')) {
						$(this).slideUp();
					}
				});

				//$('#' + enquiry).delay(600).slideDown();
				$('#' + enquiry).slideDown();
				//console.log('exists');
			}

			$this.closest('form').find('button[type="submit"]').attr('disabled', false);

		}

	}

	enquiryType();

	$('#enquiry_type').on('change', function () {

		//console.log('change');

		enquiryType();

	});

	/* Responsive nav */

	$('.js-nav-toggle').on('click', function () {

		$('.nav-dropdown').toggleClass('in');

	});

	$('.js-search-toggle').on('click', function () {

		$('.search-dropdown').toggleClass('in');

	});

	/* Double tap */

	$('nav li:has(ul)').doubleTapToGo();

	/* Button group */

	$('.btn-group').on('change', 'input[type="checkbox"]', function () {

		//console.log('change!');

		if ($(this).is(':checked')) {

			//console.log('checked');

			if ($(this, ':checked').hasClass('all')) {

				//console.log('all');

				$(this).closest('.btn-group').find('label').removeClass('active').find('input:not(.all)').prop('checked', false);

				$(this).closest('label').toggleClass('active');

			} else {

				$(this).closest('.btn-group').find('input.all').prop('checked', false).closest('label').removeClass('active');

				$(this).closest('label').toggleClass('active');

			}

		} else {
			//console.log('not checked');

			$(this).closest('label').removeClass('active');

		}

		toggleRegions();

	});

	$('.btn-group').on('change', 'input[type="radio"]', function () {

		if ($(this).is(':checked')) {

			$(this).closest('.btn-group').find('label').removeClass('active');

			$(this).closest('label').addClass('active');

		} else {

		}

	});

	/* Form reset */

	$('button[type="reset"], input[type="reset"]').on('click', function () {

		console.log('reset!');

		var checkboxes = $(this).closest('form').find('input[type="checkbox"]');

		checkboxes.each(function () {
			checkboxes.removeAttr('checked');
		});

		toggleRegions();
	});

	/* Filters */

	function filterOpen() {

		if ($('.js-filters').is(':visible')) {
			//console.log('open');

			$('.js-filter-toggle').addClass('filter-open');

		} else {
			//console.log('closed');

			$('.js-filter-toggle').removeClass('filter-open');

		}

	}

	setTimeout(function () {

		if ($('.js-filters').length > 0) {
			filterOpen();
		}

	}, 200);

	$('.js-filter-toggle').on('click', function (e) {
		e.preventDefault();

		$('.js-filters').slideToggle();

		setTimeout(function () {

			filterOpen();

		}, 500);

	});

	$('.js-filters button[type="reset"]').on('click', function (e) {

		$('.js-filter-output').find('.pet-type span').text('None');
		$('.js-filter-output').find('.content-type span').text('None');
		$('.js-filter-output').find('.event-region span').text('None');

	});

	if ($('.js-regions').length > 0) {

		//console.log('regions!');

		function toggleRegions() {

			if ($('#content_events').is(':checked') || $('#content_all').is(':checked')) {

				$('.js-regions').slideDown();

			} else {

				$('.js-regions').slideUp();
				$('.js-regions').find('input[type="checkbox"]').prop('checked', false);
				$('.js-filter-output').find('.event-region span').text('None');

			}

		}

		$('input[type="checkbox"]').on('change', function () {
			toggleRegions();
		});

		toggleRegions();

	}

	function filterOutput(identifier, output) {

		/*var filterSelected = [];
		 $('.js-filters input:checked').each(function() {
		 filterSelected.push($(this).closest('label').find('.filter-name').text());
		 });

		 $('.js-filter-output').append(filterSelected);*/

		var petTypeChecked = $('.js-filters input[name="' + identifier + '"]:checked');
		var petTypeOutput = $('.js-filter-output').find(output);
		var petTypeSelected = [];
		var $string = '';


		petTypeChecked.each(function () {
			petTypeSelected.push($(this).closest('label').find('.filter-name').text());
			$string += $(this).closest('label').find('.filter-name').text() + ', '
		});

		if (petTypeChecked.length > 0) {
			$string = $string.substring(0, $string.length - 2);

			/*if ( petTypeChecked.val() === 'all' ) {

			 petTypeOutput.text('All');

			 } else {*/

			petTypeOutput.text($string);

			//}

			// console.log(petTypeChecked.length);

		} else {

			petTypeOutput.text('None');

		}

	}

	filterOutput('animals_filter[]', '.pet-type span');
	filterOutput('content_filter[]', '.content-type span');
	filterOutput('event_filter[]', '.event-region span');

	/* Vex */

	vex.defaultOptions.className = 'vex-theme-default';

	$('[data-toggle="review"]').each(function () {
		$(this).click(function (e) {
			e.preventDefault();

			var productID = $(this).data('product_id');

			if (typeof productID == 'undefined' || productID.length < 1) {
				window.location = "/login";
				return;
			}

			vex.dialog.open({
				className: 'vex-theme-default submit-review',
				message: '<h3 class="h2 mt0 mb3">Submit a review</h3><div class="review-rating"></div>',
				input: '<input type="text" id="review_title" placeholder="Title..." class="field"><br>\
						<textarea id="review_message" placeholder="Message..." class="field"></textarea>',
				buttons: [
					$.extend({}, vex.dialog.buttons.YES, {text: 'Submit'}),
					$.extend({}, vex.dialog.buttons.NO, {text: 'Cancel'})
				],
				afterOpen: function (e) {
					$('.review-rating').rateYo({
						rating: 5,
						starWidth: '28px',
						spacing: '2px',
						normalFill: '#dddddd',
						ratedFill: '#2daee0',
						fullStar: true
					});
				},
				callback: function (data) {
					if (data === false) {
						return console.log('Cancelled');
					}

					var reviewTitle = $('#review_title').val();
					var reviewMessage = $('#review_message').val();
					var reviewRating = $('.review-rating').rateYo("rating");

					$.ajax({
						method: "POST",
						url: "/ajax/add_review/",
						data: {
							review_title: reviewTitle,
							review: reviewMessage,
							rating: reviewRating,
							product_id: productID
						}
					})

						.done(function (msg) {

							if (msg == 'submitted') {

								vex.dialog.alert('<p>Your review has been submitted!</p><p>It is now entering a moderation process.</p>');

							} else {

								var titleError = '';
								var messageError = '';
								var ratingError = '';

								if (reviewTitle == '') {
									console.log('title empty');
									titleError = '<p>The title was empty</p>';
								}

								if (reviewMessage == '') {
									console.log('message empty');
									messageError = '<p>The message was empty</p>';
								}

								if (reviewRating == '') {
									console.log('rating empty');
									ratingError = '<p>No rating was set</p>';
								}

								vex.dialog.alert('<strong class="block mb2">There has been an error!</strong>' + titleError + messageError + ratingError);

							}

						});

				}
			});
			return false;
		});
	});

});

require('./onload');
