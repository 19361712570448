jQuery(document).ready(function ($) {
	$("#gform_1").validate({
		//ignore: ':not(:visible)',
		errorClass: "is-error",
		rules: {
			// first name
			input_1: {
				required: true,
				lettersonly: true
			},
			// last name
			input_3: {
				required: true,
				lettersonly: true
			},
			// email
			input_4: {
				required: true,
				email: true
			},
			// telephone
			input_5: {
				required: true,
				phoneUK: true
			},
			// country
			input_6: "required",

			// enquiry type
			input_7: "required",

			// product query
			input_9: "required",
			input_10: "required",

			// general enquiry
			input_14: "required",

			// submit event
			input_16: "required",
			input_17: "required",
			input_18: "required",
			'input_19[]': "required",
			input_19: "required",
			input_20: "required",
			input_21: {
				required: true,
				postcodeUK: true
			},
			input_36: "required",
			input_38: "required",
			input_22: "required",
			input_23: {
				required: true,
				extension: 'png|jpe?g|gif'
			},

			// distributor enquiry
			input_25: "required",
			input_26: {
				required: true,
				postcodeUK: true
			},
			input_41: "required",
			input_43: "required",
			input_27: "required",
			input_28: "required",

			// media enquiry
			input_30: "required",
			input_31: {
				required: true,
				postcodeUK: true
			},
			input_48: "required",
			input_50: "required",
			input_32: "required",
			input_33: "required"
		},
		messages: {
			// email
			input_4: {
				email: "Please enter a valid email address."
			},
			// telephone
			input_5: {
				phoneUK: "Please enter a valid telephone number."
			},
			agree: "Please accept our policy"
		},
		errorElement: 'span',
		errorPlacement: function (error, element) {
			var parent = element.parents('.gfield');
			if (parent.length) {
				error.appendTo(parent);
			} else {
				error.insertAfter(element);
			}
		}
	});

	$('#gform_1').on('click', 'input[type="submit"]', function () {

		if ($("#gform_1").valid()) {
			console.log('valid');
			$('#gform_1').submit();
		} else {
			console.log('invalid');
		}

	});

	/* Gravity forms */

	$('.gform_wrapper').find('textarea').each(function () {
		$(this).closest('li').attr('style', 'width: 100% !important');
	})

	$('.gform_wrapper').find('.large').each(function () {
		$(this).closest('li').before('<li style="width: 100% !important; height: 0px; display: block; clear: both;"></li>');
	});

	$('.gform_wrapper').find('.ginput_container_checkbox').each(function () {
		$(this).closest('li').attr('style', 'width: 100% !important')
	});
})